@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&family=Inconsolata&display=swap");

body {
  background: #e2def7 !important;
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carousel-caption {
  background: #6a6c6d9e;
}

.message-box-content {
  font-size: small;
}

.bg-header {
  background: #fff;
}

.logo-banner::before {
  background: #2d3e50 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  width: 230px;
}

.logo-banner {
  text-align: center;
  margin: 0 0 50px;
  position: relative;
  padding: 0 0 5px;
}

.logo-banner::after {
  background: rgba(0, 0, 0, 0) url(./assets/images/sm_logo.png) no-repeat scroll
    0 0;
  bottom: -15px;
  color: #1bb4b9;
  content: "";
  font-size: 14px;
  height: 40px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  width: 40px;
  z-index: 9;
}

.dropdown-toggle::after {
  content: none !important;
}

.navbar-style {
  border-bottom: 2px solid #419fb5;
}

.link-text {
  color: #00adff;
}

.link-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.footer-button-link {
  cursor: pointer;
  font-weight: 500;
}
.footer-button-link:hover {
  color: #42a2b9;
  text-decoration: underline;
  font-weight: 400;
  transition: 400ms;
}

.circle-button {
  border: 1px solid #42a2b9 !important;
  border-radius: 20px !important;
  background: white !important;
  color: #42a2b9 !important;
  margin: 0px 5px 0px 5px;
}

.circle-button-padding {
  padding: 7px 10px !important;
}

.sidebar-department {
  cursor: pointer;
}

.error-page {
  box-sizing: border-box;
  height: 100%;
  background-color: #000000;
  background-image: radial-gradient(#0a818a, #041607),
    url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Inconsolata", Helvetica, sans-serif;
  font-size: 1.5rem;
  color: rgba(128, 247, 255, 0.8);
  text-shadow: 0 0 1ex rgb(40, 84, 230), 0 0 2px rgba(255, 255, 255, 0.8);
}

.error-page .noise {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: 0.02;
}

.error-page .overlay {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: auto 4px;
  z-index: 1;
}

.error-page .overlay::before {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    transparent 0%,
    rgba(32, 109, 128, 0.2) 2%,
    rgba(32, 109, 128, 0.8) 3%,
    rgba(32, 109, 128, 0.2) 3%,
    transparent 100%
  );
  background-repeat: no-repeat;
  animation: scan 7.5s linear 0s infinite;
}

@keyframes scan {
  0% {
    background-position: 0 -100vh;
  }
  35%,
  100% {
    background-position: 0 100vh;
  }
}

.error-page .terminal {
  box-sizing: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 4rem;
  text-transform: uppercase;
}

.error-page .output {
  color: rgba(128, 251, 255, 0.8);
  text-shadow: 0 0 1px rgba(51, 255, 245, 0.4), 0 0 2px rgba(255, 255, 255, 0.8);
}

.error-page .output::before {
  content: "> ";
}

.error-page a {
  color: #fff;
  text-decoration: none;
}

.error-page a::before {
  content: "[";
}

.error-page a::after {
  content: "]";
}

.error-page .errorcode {
  color: white;
}

.navbar-nav a {
  font-weight: 500;
}

.navbar-nav a:hover {
  background: #419fb5;
  color: #ffffff !important;
  font-weight: 500;
}

.video-card {
  max-height: 70vh;
  overflow: scroll;
}

.required-input::after {
  content: "*";
  color: red;
  margin-left: 3px;
  font-size: 16px;
}

.marquee-container.horizontal::before {
  background: none !important;
}
.marquee-container.horizontal::after {
  background: none !important;
}
.navbar-nav div a {
  color: #f5d768 !important;
  font-size: 1.1em;
}
.dropdown-menu {
  background: #594ac9 !important;
}
.navbar-color-text {
  color: #f5d768 !important;
}
.navbar-color {
  background: #584ac9;
}

.notice-info {
  background: #922a9f !important;
  font-weight: 800 !important;
}
.notice-board {
  background: #1a26bb !important;
}
.notice-footer {
  background: #922a9f !important;
}
.notice-footer span {
  color: #fff;
  font-weight: 800;
}
.marquee-item div p {
  color: #fff;
}
.green {
  background: #15b555 !important;
}
.yallow {
  background: #f5f05f !important;
}
.dark-blue {
  background: #1e33bb !important;
}
.dark-green {
  background: #3a5a54 !important;
}
.pink {
  background: #e19090 !important;
}
.antiquewhite {
  background: rgb(226, 209, 187) !important;
}
.carousel-caption {
  bottom: -30px !important;
  background: none !important;
}
.index-card {
  min-height: 400px !important;
  max-height: 400px !important;
  overflow: scroll !important;
  background: antiquewhite;
}

.modal-close-abolute button {
  position: absolute;
  right: 15px;
}
