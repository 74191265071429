.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

footer.sticky-footer {
  padding: 1rem 0;
  flex-shrink: 0;
}

.no-text-decoration{
  text-decoration: none !important;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #419fb5;
  border-color: #419fb5;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.react-confirm-alert-body {
  color: #419fb5 !important;
}

.react-confirm-alert-button-group > button {
  background: #419fb5 !important;
}

.react-confirm-alert-body > h1 {
  font-size: 2em !important;
}
